// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useDate = () => {
    // ex) new Date('2022-01-01 00:00:00') → '2020/1/1 00:00:00'
    const dateFormat = (date: Date): string => {
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        const sec = date.getSeconds().toString().padStart(2, '0');

        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hour}:${min}:${sec}`;
    };

    /**
     * 日付を数値型で取得
     * ex) '2022-01-11' → 11
     */
    const getDateNumber = (date: string): number => {
        return new Date(date).getDate();
    };

    const getToday = (): string => {
        return new Date().toISOString().substring(0, 10);
    };

    /**
     * 昨日を取得
     */
    const getYesterday = (): string => {
        const date = new Date();
        date.setDate(date.getDate() - 1);

        return date.toISOString().substring(0, 10);
    };

    /**
     * 今月を取得
     */
    const getThisMonth = (): number => {
        return new Date().getMonth() + 1;
    };

    /**
     * 来月を取得
     */
    const getNextMonth = (): string => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 2).toISOString().substring(0, 7);
    };

    /**
     * 先月を取得
     */
    const getLastMonth = (): string => {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth()).toISOString().substring(0, 7);
    };

    /**
     * 月初日を取得
     *
     * @param date
     */
    const getFirstOfMonth = (date: Date): string => {
        date.setDate(1);
        return new Date(date).toISOString().substring(0, 10);
    };

    /**
     * 月末日を取得
     *
     * @param date
     */
    const getEndOfMonth = (date: Date): string => {
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);

        return new Date(date).toISOString().substring(0, 10);
    };

    /**
     * 期間表示に変換
     * ex)2022年6月 → 2022年6月1日 ~ 2022年6月30日
     * ※当月の場合は1日 ~ 昨日に変換する。
     *
     * @param date
     */
    const convertToPeriodFormat = (date: Date): string => {
        const startDate = getFirstOfMonth(date);
        const endOfMonth = getEndOfMonth(date);
        const endDate = new Date(getYesterday()) <= new Date(endOfMonth) ? getYesterday() : endOfMonth;

        return `${startDate} ~ ${endDate}`;
    };

    return {
        dateFormat,
        getToday,
        getYesterday,
        getThisMonth,
        getNextMonth,
        getLastMonth,
        getFirstOfMonth,
        getEndOfMonth,
        convertToPeriodFormat,
        getDateNumber,
    };
};
