import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisPlanDeleteDialog = () => {
    const analysisPlanDeleteDialog = ref(false);

    provide('analysisPlanDeleteDialog', analysisPlanDeleteDialog);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisPlanDeleteDialog = () => {
    const analysisPlanDeleteDialog = inject('analysisPlanDeleteDialog') as Ref<boolean>;

    const openDeleteDialog = (): void => {
        analysisPlanDeleteDialog.value = true;
    };

    const closeDialog = (): void => {
        analysisPlanDeleteDialog.value = false;
    };

    return {
        analysisPlanDeleteDialog,
        openDeleteDialog,
        closeDialog,
    };
};
