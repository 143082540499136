import { isFailedResponse } from '@/models/interface/common';
import { AnalysisPlanDeleteService } from '@/models/service/Management/AnalysisPlanDeleteService';
import { AnalysisPlanInfo } from '@/models/interface/Management/IAnalysisPlanRepository';
import { useCircular } from '@/composables/function/Common/useCircular';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { ErrorStatus } from '@/types/common';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';

const PlanDelete = container.resolve(AnalysisPlanDeleteService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisPlanManagement = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const targetPlan = ref<AnalysisPlanInfo>();
    const isDeletingPlanProgressing = ref(false);

    provide('error', error);
    provide('targetPlan', targetPlan);
    provide('isDeletingPlanProgressing', isDeletingPlanProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisPlanManagement = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const targetPlan = inject('targetPlan') as Ref<AnalysisPlanInfo>;
    const isDeletingPlanProgressing = inject('isDeletingPlanProgressing') as Ref<boolean>;

    const { progressStart, progressEnd } = useCircular();

    const { planList } = useCompanyPlan();

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initTargetPlan = (): void => {
        targetPlan.value = {} as AnalysisPlanInfo;
    };

    const setTargetPlan = (planId: number): void => {
        targetPlan.value = planList.value.find(plan => plan.id === planId) ?? planList.value[0];
    };

    const deletePlan = async (): Promise<void> => {
        progressStart(isDeletingPlanProgressing);
        const response = await PlanDelete.handle(targetPlan.value.id);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isDeletingPlanProgressing);
            return;
        }

        initStatus();
        progressEnd(isDeletingPlanProgressing);
    };

    return {
        error,
        targetPlan,
        isDeletingPlanProgressing,
        initTargetPlan,
        setTargetPlan,
        deletePlan,
    };
};
