import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyContractConfirmDialog = () => {
    const contractDialogFlag = ref(false);
    const memoFlag = ref(false);

    provide('contractDialogFlag', contractDialogFlag);
    provide('memoFlag', memoFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyContractConfirmDialog = () => {
    const contractDialogFlag = inject('contractDialogFlag') as Ref<boolean>;
    const memoFlag = inject('memoFlag') as Ref<boolean>;

    const openContractDialog = (): void => {
        contractDialogFlag.value = true;
    };

    const closeDialog = (): void => {
        memoFlag.value = false;
        contractDialogFlag.value = false;
    };

    return {
        contractDialogFlag,
        memoFlag,
        openContractDialog,
        closeDialog,
    };
};
