


















































import { defineComponent, onMounted } from '@vue/composition-api';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useAnalysisPlanManagement } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanManagement';
import { useAnalysisPlanDeleteDialog } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanDeleteDialog';
import { useCompanyContractConfirmDialog } from '@/composables/store/Management/AnalysisPlan/useCompanyContractConfirmDialog';
import { useTime } from '@/composables/function/Common/useTime';
import { useRouter } from '@/composables/function/Common/useRouter';

export default defineComponent({
    setup() {
        const router = useRouter();
        const {
            planList,
            currentPlan,
            isPlanFetchListProgressing,
            fetchPlanList,
            setSelectedPlan,
            planKindLabel,
            isCustomPlan,
        } = useCompanyPlan();

        const { setTargetPlan } = useAnalysisPlanManagement();
        const { openDeleteDialog } = useAnalysisPlanDeleteDialog();
        const { openContractDialog } = useCompanyContractConfirmDialog();
        const { convertToHourFormatNumber } = useTime();

        onMounted(() => {
            fetchPlanList();
        });

        const selectPlan = (planId: number): void => {
            setSelectedPlan(planId);
            openContractDialog();
        };

        const selectTargetPlan = (planId: number): void => {
            setTargetPlan(planId);
            openDeleteDialog();
        };

        return {
            router,
            planList,
            currentPlan,
            isPlanFetchListProgressing,
            convertToHourFormatNumber,
            selectPlan,
            selectTargetPlan,
            planKindLabel,
            isCustomPlan,
        };
    },
});
