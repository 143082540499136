import { isFailedResponse } from '@/models/interface/common';
import { AnalysisPlanFetchListService } from '@/models/service/Management/AnalysisPlanFetchListService';
import { AnalysisPlanCompanyContractService } from '@/models/service/Management/AnalysisPlanCompanyContractService';
import { AnalysisPlanInfo, CUSTOM_PLAN_KIND } from '@/models/interface/Management/IAnalysisPlanRepository';
import { CompanyBasicInfo } from '@/models/interface/Management/ICompanyRepository';
import { useDate } from '@/composables/function/Common/useDate';
import { useCircular } from '@/composables/function/Common/useCircular';
import { ErrorStatus } from '@/types/common';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';

const AnalysisPlanFetchIndex = container.resolve(AnalysisPlanFetchListService);
const CompanyContract = container.resolve(AnalysisPlanCompanyContractService);

export type ContractFormInfo = {
    date: string;
    memo?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyPlan = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const planList = ref<AnalysisPlanInfo[]>([]);
    const company = ref<CompanyBasicInfo>();
    const currentPlan = ref<AnalysisPlanInfo>();
    const selectedPlan = ref<AnalysisPlanInfo>();
    const contractFormInfo = ref<ContractFormInfo>({ date: '' });
    const isPlanFetchListProgressing = ref(false);
    const isContractProgressing = ref(false);

    provide('error', error);
    provide('planList', planList);
    provide('company', company);
    provide('currentPlan', currentPlan);
    provide('selectedPlan', selectedPlan);
    provide('contractFormInfo', contractFormInfo);
    provide('isPlanFetchListProgressing', isPlanFetchListProgressing);
    provide('isContractProgressing', isContractProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyPlan = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const planList = inject('planList') as Ref<AnalysisPlanInfo[]>;
    const company = inject('company') as Ref<CompanyBasicInfo>;
    const currentPlan = inject('currentPlan') as Ref<AnalysisPlanInfo>;
    const selectedPlan = inject('selectedPlan') as Ref<AnalysisPlanInfo>;
    const contractFormInfo = inject('contractFormInfo') as Ref<ContractFormInfo>;
    const isPlanFetchListProgressing = inject('isPlanFetchListProgressing') as Ref<boolean>;
    const isContractProgressing = inject('isContractProgressing') as Ref<boolean>;

    const { getToday, getNextMonth, getFirstOfMonth } = useDate();

    const { progressStart, progressEnd } = useCircular();

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initSelectedPlan = (): void => {
        selectedPlan.value = {} as AnalysisPlanInfo;
    };

    const initTargetCompany = (): void => {
        company.value = {} as CompanyBasicInfo;
    };

    const initContractFormInfo = (): void => {
        contractFormInfo.value = {
            date: getFirstOfMonth(new Date(getNextMonth())),
            memo: '',
        };
    };

    const setPlanList = (data: AnalysisPlanInfo[]): void => {
        planList.value = data;
    };

    const setTargetCompany = (data: CompanyBasicInfo): void => {
        company.value = data;
    };

    const setSelectedPlan = (planId: number): void => {
        selectedPlan.value = planList.value.find(plan => plan.id === planId) ?? planList.value[0];
    };

    const fetchPlanList = async (): Promise<void> => {
        progressStart(isPlanFetchListProgressing);
        const response = await AnalysisPlanFetchIndex.handle();

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isPlanFetchListProgressing);
            return;
        }

        initStatus();
        progressEnd(isPlanFetchListProgressing);
        setPlanList(response.data.data);
    };

    const postContract = async (): Promise<void> => {
        progressStart(isContractProgressing);
        const response = await CompanyContract.handle(
            company.value.id,
            selectedPlan.value.id,
            getToday(),
            contractFormInfo.value.date,
            contractFormInfo.value.memo,
        );

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isContractProgressing);
            return;
        }

        initStatus();
        progressEnd(isContractProgressing);
    };

    const planKindLabel = (planKind: number): string =>
        planKind === CUSTOM_PLAN_KIND ? 'カスタムプラン' : '基本プラン';

    const isCustomPlan = (planKind: number): boolean => planKind === CUSTOM_PLAN_KIND;

    return {
        error,
        planList,
        company,
        selectedPlan,
        currentPlan,
        contractFormInfo,
        isPlanFetchListProgressing,
        isContractProgressing,
        fetchPlanList,
        setTargetCompany,
        postContract,
        setSelectedPlan,
        initSelectedPlan,
        initTargetCompany,
        initContractFormInfo,
        initStatus,
        planKindLabel,
        isCustomPlan,
    };
};
