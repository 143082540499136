// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTime = () => {
    /**
     * ex) 90sec → 1分30秒に変換
     * @param number sec
     * @returns string
     */
    const convertToMinutesFormat = (sec: number): string => {
        const min = Math.floor((sec % 3600) / 60);
        const rem = Math.floor(sec % 60);

        return min ? `${min}分` + rem + '秒' : rem + '秒';
    };

    /**
     * ex) 90sec → 01分30秒に変換 or 30sec → 30秒
     * グラフ以外では、幅を気にせず二桁表示
     * @param number sec
     * @returns string
     */
    const convertToMinutesFormatNumber = (sec: number): string => {
        const min = Math.floor((sec % 3600) / 60);
        const rem = Math.floor(sec % 60);

        return min ? `${min < 10 ? '0' + min : min}分` + rem + '秒' : rem + '秒';
    };

    /**
     * ex) 4000秒 → 1時間6分40秒に変換
     * @returns string
     * @param sec
     */
    const convertToHourFormat = (sec: number): string => {
        const hour = Math.floor(sec / 3600);
        const minute = Math.floor((sec % 3600) / 60);
        const rem = sec % 60;

        return hour ? `${hour}時間${minute}分${rem}秒` : minute ? `${minute}分${rem}秒` : `${rem}秒`;
    };

    /**
     * 時間→秒数変換
     * @param hour string
     * @returns string
     */
    const convertToSecondFormatNumber = (hour: string): number => {
        return Number(hour) * 3600;
    };

    /**
     * 秒数→時間変換
     * @param sec
     * @returns
     */
    const convertToHourFormatNumber = (sec: number): number => {
        return Math.floor(sec / 3600);
    };

    /**
     * ex) 90sec → 1:30に変換 or 30sec → 0:30
     * 音声再生操作パネルで使用
     * @param number sec
     * @returns string
     */
    const cutOffDecimalPointTime = (sec: number): string => {
        const min = Math.floor((sec % 3600) / 60);
        let rem: number | string = Math.floor(sec % 60);
        rem = rem < 10 ? '0' + rem : rem;

        return min ? `${min}:` + rem : '0:' + rem;
    };

    return {
        convertToMinutesFormat,
        convertToMinutesFormatNumber,
        convertToHourFormat,
        cutOffDecimalPointTime,
        convertToSecondFormatNumber,
        convertToHourFormatNumber,
    };
};
