import { inject, injectable } from 'tsyringe';
import {
    AnalysisPlanFetchResponse,
    IAnalysisPlanRepository,
} from '@/models/interface/Management/IAnalysisPlanRepository';

@injectable()
export class AnalysisPlanFetchListService {
    constructor(@inject('IAnalysisPlanRepository') private readonly repository: IAnalysisPlanRepository) {}

    async handle(): Promise<AnalysisPlanFetchResponse> {
        return this.repository.getAnalysisPlanIndex();
    }
}
