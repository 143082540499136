








import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const progressCircularText = props.text;
        return {
            progressCircularText,
        };
    },
});
