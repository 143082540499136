import { inject, injectable } from 'tsyringe';
import { AnalysisPlanContractResponse } from '@/models/interface/Management/IAnalysisPlanRepository';
import { IAnalysisPlanRepository } from '@/models/interface/Management/IAnalysisPlanRepository';

@injectable()
export class AnalysisPlanCompanyContractService {
    constructor(@inject('IAnalysisPlanRepository') private readonly repository: IAnalysisPlanRepository) {}

    async handle(
        companyId: number,
        planId: number,
        applyDate: string,
        startDate: string,
        memo?: string,
    ): Promise<AnalysisPlanContractResponse> {
        return this.repository.postAnalysisPlanCompanyContract(companyId, planId, applyDate, startDate, memo);
    }
}
