import { Ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCircular = () => {
    const progressStart = (progressing: Ref<boolean>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        progressing.value = true;
    };

    const progressEnd = (progressing: Ref<boolean>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        progressing.value = false;
    };

    return {
        progressStart,
        progressEnd,
    };
};
