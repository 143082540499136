import { inject, injectable } from 'tsyringe';
import { AnalysisPlanDeleteResponse } from '@/models/interface/Management/IAnalysisPlanRepository';
import { IAnalysisPlanRepository } from '@/models/interface/Management/IAnalysisPlanRepository';

@injectable()
export class AnalysisPlanDeleteService {
    constructor(@inject('IAnalysisPlanRepository') private readonly repository: IAnalysisPlanRepository) {}

    async handle(planId: number): Promise<AnalysisPlanDeleteResponse> {
        return this.repository.postAnalysisPlanDelete(planId);
    }
}
